/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select';
import { useAuth } from '../../../auth'
import clsx from 'clsx'
import axios from 'axios'
import { DeletEmploye_URL, Employe_URL, ThirdPartyOption_URL, ThirdParty_URL } from '../../../auth/core/_requests'

const initialValues = {
  LegalName: "",
  LegalFormId: 0,
  ICE: "",
  CNSS: "",
  RC_CIN: "",
  IdF: "",
  CurrencyId: 1,
  Address1: "",
  Address2: "",
  State: "",
  City: "",
  ZipCode: "",
  CountryId: 1,
  Email: "",
  PhoneNumber: "",
  LandlineNumber: "",
  BankAccount: "",
  RIB: "",
  BankId: 0,
  PaymentMethodId: 0,
  PaymentTermId: 0
}

interface initialValuesObj {
  LegalName: string,
  LegalFormId: number,
  ICE: string,
  CNSS: string,
  RC_CIN: string,
  IdF: string,
  CurrencyId: number,
  Address1: string,
  Address2: string,
  State: string,
  City: string,
  ZipCode: string,
  CountryId: number,
  Email: string,
  PhoneNumber: string,
  LandlineNumber: string,
  BankAccount: string,
  RIB: string,
  BankId: string,
  PaymentMethodId: number,
  PaymentTermId: number,
}

const ThirdPartyEdit = ({ id, getData, setshow }: { id?: number, getData: Function, setshow: Function }) => {
  const [model, setModel] = useState<any>(null)
  const [options, setOptions] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [FormikStatus, setFormikStatus] = useState("")
  const stepperRef = useRef<HTMLDivElement | null>(null)

  const { currentUser } = useAuth()

  useEffect(
    () => {
      GetOptions();
      if (!!id && id != 0) GetData();
      else {
        setLoadingForm(true)
      }
    },
    []
  )

  const GetData = async () => {
    setLoading(true)
    axios
      .get(ThirdParty_URL + '/' + id, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
      .then((x) => {
        setLoadingForm(true)
        setLoading(false)
        setModel(x['data']['thirdParty'])
      })
      .catch((error) => {
        setLoadingForm(false)
        setLoading(false)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }
      })
  }

  const GetOptions = async () => {
    setLoading(true)
    axios
      .get(ThirdPartyOption_URL, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
      .then((x) => {
        setLoading(false)
        var str = JSON.stringify(x['data']);
        str = str.replaceAll('"id":', '"value":')
        setOptions(JSON.parse(str)['data'])
        //console.log("JSON", JSON.parse(str)['data'])
        console.log("legalForms", JSON.parse(str)['data'][4]['legalForms'])
      })
      .catch((error) => {
        setLoadingForm(false)
        setLoading(false)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }
      })
  }

  const schema = Yup.object().shape({
    /* email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Name is required'),
    phone: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Phone is required'), */
  })

  const submitStep = async (values: initialValuesObj, actions: FormikValues) => {
    setLoading(true)
    try {
      if (!!id && id != 0)
        axios.post<any>(
          ThirdParty_URL + id,
          {
            LegalName: values.LegalName,
            LegalFormId: values.LegalFormId,
            ICE: values.ICE,
            CNSS: values.CNSS,
            RC_CIN: values.RC_CIN,
            IdF: values.IdF,
            CurrencyId: values.CurrencyId,
            Address1: values.Address1,
            Address2: values.Address2,
            State: values.State,
            City: values.City,
            ZipCode: values.ZipCode,
            CountryId: values.CountryId,
            Email: values.Email,
            PhoneNumber: values.PhoneNumber,
            LandlineNumber: values.LandlineNumber,
            BankAccount: values.BankAccount,
            RIB: values.RIB,
            BankId: values.BankId,
            PaymentMethodId: values.PaymentMethodId,
            PaymentTermId: values.PaymentTermId,
          },
          { headers: { Authorization: `Bearer ${currentUser?.token}` } }
        ).then((x) => {
          actions.resetForm()
          setshow(false)
          setLoading(false)
          getData()
        })
          .catch((error) => {
            setLoading(false)
            if (error.response?.data.errors) {
              var errors = ''
              var ViewModelErrors = error.response?.data.errors

              for (const [key, value] of Object.entries(ViewModelErrors)) {
                var val: any = value as any;
                errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
              }
              setFormikStatus(errors)
            } else {
              setFormikStatus(error.response?.data.message)
            }
          })
      else
        axios.post<any>(
          ThirdParty_URL,
          {
            LegalName: values.LegalName,
            LegalFormId: values.LegalFormId,
            ICE: values.ICE,
            CNSS: values.CNSS,
            RC_CIN: values.RC_CIN,
            IdF: values.IdF,
            CurrencyId: values.CurrencyId,
            Address1: values.Address1,
            Address2: values.Address2,
            State: values.State,
            City: values.City,
            ZipCode: values.ZipCode,
            CountryId: values.CountryId,
            Email: values.Email,
            PhoneNumber: values.PhoneNumber,
            LandlineNumber: values.LandlineNumber,
            BankAccount: values.BankAccount,
            RIB: values.RIB,
            BankId: values.BankId,
            PaymentMethodId: values.PaymentMethodId,
            PaymentTermId: values.PaymentTermId,
          },
          { headers: { Authorization: `Bearer ${currentUser?.token}` } }
        ).then((x) => {
          actions.resetForm()
          setshow(false)
          setLoading(false)
          getData()
        })
          .catch((error) => {
            setLoading(false)
            if (error.response?.data.errors) {
              var errors = ''
              var ViewModelErrors = error.response?.data.errors

              for (const [key, value] of Object.entries(ViewModelErrors)) {
                var val: any = value as any;
                errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
              }
              setFormikStatus(errors)
            } else {
              setFormikStatus(error.response?.data.message)
            }
          })
    } catch (error) { }
  }

  return (
    <>
      <div className='modal-header pb-0 border-0 w-100'>
        <div className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-start'>
          <label className='form-label fw-bolder text-dark fs-6'>{!id ? 'Add new Third Party' : 'Edit Third Party : ' + (model?.name ?? '')}</label>
        </div>
        <div
          onClick={() => {
            setshow(false)
          }}
          className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-end'
          data-bs-dismiss='modal'
        >
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>

      <div className='modal-body'>
        <div
          ref={stepperRef}
        >

          {FormikStatus && (
            <div className=' mx-auto w-100 alert alert-danger'>
              <div className='alert-text font-weight-bold'
                dangerouslySetInnerHTML={{ __html: FormikStatus }}
              />
            </div>
          )}

          {!loadingForm && (
            <div className='w-100 text-center mt-20 mb-20'>
              <div className="spinner-grow text-primary" style={{ height: '8rem', width: '8rem' }} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {loadingForm &&
            <Formik validationSchema={schema} enableReinitialize={false} initialValues={model ?? initialValues} onSubmit={submitStep}>
              {({
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form className='mx-auto w-100 pt-6 pb-10' id='kt_create_account_form'>

                  <div className='current' data-kt-stepper-element='content'>
                    <div className='row col-12'>
                      <div className='fv-row col-6 mb-8'>
                        <div className="fv-row">
                          <label className='form-label fw-bolder text-dark fs-6 required'>Legal Name</label>
                          <input
                            placeholder='Legal Name'
                            type='text'
                            autoComplete='off'
                            value={values.LegalName}
                            onChange={(e) => {
                              setFieldValue("LegalName", e.target.value)
                            }}
                            className={clsx(
                              'form-control bg-transparent',
                              { 'is-invalid': touched.LegalName && errors.LegalName },
                              {
                                'is-valid': touched.LegalName && !errors.LegalName,
                              }
                            )}
                          />
                          {touched.LegalName && errors.LegalName && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.LegalName}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='mb-10 col-6'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Legal Forms</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            //value={{ label: ActivityTypes.find(x => x.value == values.activityTypeId)?.label, value: values.activityTypeId }}
                            onChange={(e) => {
                              //setFieldValue("LegalFormId", e?.value)
                            }}
                            isSearchable={true}
                            name="activityTypeId"
                            options={options && options[1]['countries']}
                          />
                          {touched.LegalFormId && errors.LegalFormId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.LegalFormId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-8'>
                        <div className="fv-row">
                          <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
                          <input
                            placeholder='Email'
                            type='text'
                            autoComplete='off'
                            value={values.Email}
                            onChange={(e) => {
                              setFieldValue("Email", e.target.value)
                            }}
                            className={clsx(
                              'form-control bg-transparent',
                              { 'is-invalid': touched.Email && errors.Email },
                              {
                                'is-valid': touched.Email && !errors.Email,
                              }
                            )}
                          />
                          {touched.Email && errors.Email && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.Email}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>ICE</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='ICE'
                              autoComplete='off'
                              value={values.ICE}
                              onChange={(e) => {
                                setFieldValue("ICE", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.ICE && errors.ICE,
                                },
                                {
                                  'is-valid': touched.ICE && !errors.ICE,
                                }
                              )}
                            />
                            {touched.ICE && errors.ICE && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.ICE}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>CNSS</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='CNSS'
                              autoComplete='off'
                              value={values.CNSS}
                              onChange={(e) => {
                                setFieldValue("CNSS", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.CNSS && errors.CNSS,
                                },
                                {
                                  'is-valid': touched.CNSS && !errors.CNSS,
                                }
                              )}
                            />
                            {touched.CNSS && errors.CNSS && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.CNSS}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>IdF</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='IdF'
                              autoComplete='off'
                              value={values.IdF}
                              onChange={(e) => {
                                setFieldValue("IdF", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.IdF && errors.IdF,
                                },
                                {
                                  'is-valid': touched.IdF && !errors.IdF,
                                }
                              )}
                            />
                            {touched.IdF && errors.IdF && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.IdF}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='mb-10 col-6'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Currency</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            //value={{ label: ActivityTypes.find(x => x.value == values.activityTypeId)?.label, value: values.activityTypeId }}
                            onChange={(e) => {
                              //setFieldValue("CurrencyId", e?.value)
                            }}
                            isSearchable={true}
                            name="CurrencyId"
                            options={options && options[1]['countries']}
                          />
                          {touched.CurrencyId && errors.CurrencyId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.CurrencyId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>Address 1</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='Address1'
                              autoComplete='off'
                              value={values.Address1}
                              onChange={(e) => {
                                setFieldValue("Address1", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.Address1 && errors.Address1,
                                },
                                {
                                  'is-valid': touched.Address1 && !errors.Address1,
                                }
                              )}
                            />
                            {touched.Address1 && errors.Address1 && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.Address1}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>Address 2</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='Address2'
                              autoComplete='off'
                              value={values.Address2}
                              onChange={(e) => {
                                setFieldValue("Address2", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.Address2 && errors.Address2,
                                },
                                {
                                  'is-valid': touched.Address2 && !errors.Address2,
                                }
                              )}
                            />
                            {touched.Address2 && errors.Address2 && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.Address2}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>State</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='State'
                              autoComplete='off'
                              value={values.CNSS}
                              onChange={(e) => {
                                setFieldValue("State", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.State && errors.State,
                                },
                                {
                                  'is-valid': touched.State && !errors.State,
                                }
                              )}
                            />
                            {touched.State && errors.State && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.State}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>City</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='City'
                              autoComplete='off'
                              value={values.CNSS}
                              onChange={(e) => {
                                setFieldValue("City", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.City && errors.City,
                                },
                                {
                                  'is-valid': touched.City && !errors.City,
                                }
                              )}
                            />
                            {touched.City && errors.City && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.City}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>ZipCode</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='ZipCode'
                              autoComplete='off'
                              value={values.ZipCode}
                              onChange={(e) => {
                                setFieldValue("ZipCode", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.ZipCode && errors.ZipCode,
                                },
                                {
                                  'is-valid': touched.ZipCode && !errors.ZipCode,
                                }
                              )}
                            />
                            {touched.ZipCode && errors.ZipCode && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.ZipCode}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='mb-10 col-6'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Country</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            //value={{ label: ActivityTypes.find(x => x.value == values.activityTypeId)?.label, value: values.activityTypeId }}
                            onChange={(e) => {
                              //setFieldValue("CountryId", e?.value)
                            }}
                            isSearchable={true}
                            name="CountryId"
                            options={options && options[1]['countries']}
                          />
                          {touched.CountryId && errors.CountryId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.CountryId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='Phone Number'
                              autoComplete='off'
                              value={values.PhoneNumber}
                              onChange={(e) => {
                                setFieldValue("PhoneNumber", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.PhoneNumber && errors.PhoneNumber,
                                },
                                {
                                  'is-valid': touched.PhoneNumber && !errors.PhoneNumber,
                                }
                              )}
                            />
                            {touched.PhoneNumber && errors.PhoneNumber && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.PhoneNumber}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>Landline Number</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='Landline Number'
                              autoComplete='off'
                              value={values.LandlineNumber}
                              onChange={(e) => {
                                setFieldValue("LandlineNumber", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.LandlineNumber && errors.LandlineNumber,
                                },
                                {
                                  'is-valid': touched.LandlineNumber && !errors.LandlineNumber,
                                }
                              )}
                            />
                            {touched.LandlineNumber && errors.LandlineNumber && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.LandlineNumber}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>Bank Account</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='Bank Account'
                              autoComplete='off'
                              value={values.BankAccount}
                              onChange={(e) => {
                                setFieldValue("BankAccount", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.BankAccount && errors.BankAccount,
                                },
                                {
                                  'is-valid': touched.BankAccount && !errors.BankAccount,
                                }
                              )}
                            />
                            {touched.BankAccount && errors.BankAccount && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.BankAccount}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      <div className='mb-10 col-6'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Bank</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            //value={{ label: ActivityTypes.find(x => x.value == values.activityTypeId)?.label, value: values.activityTypeId }}
                            onChange={(e) => {
                              //setFieldValue("BankId", e?.value)
                            }}
                            isSearchable={true}
                            name="BankId"
                            options={options && options[1]['countries']}
                          />
                          {touched.BankId && errors.BankId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.BankId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='mb-10 col-6'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Payment Method</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            //value={{ label: ActivityTypes.find(x => x.value == values.activityTypeId)?.label, value: values.activityTypeId }}
                            onChange={(e) => {
                              //setFieldValue("PaymentMethodId", e?.value)
                            }}
                            isSearchable={true}
                            name="PaymentMethodId"
                            options={options && options[1]['countries']}
                          />
                          {touched.PaymentMethodId && errors.PaymentMethodId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.PaymentMethodId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='mb-10 col-6'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Payment Term</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            //value={{ label: ActivityTypes.find(x => x.value == values.activityTypeId)?.label, value: values.activityTypeId }}
                            onChange={(e) => {
                              //setFieldValue("PaymentTermId", e?.value)
                            }}
                            isSearchable={true}
                            name="PaymentTermId"
                            options={options && options[1]['countries']}
                          />
                          {touched.PaymentTermId && errors.PaymentTermId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.PaymentTermId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='fv-row col-6 mb-6'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6'>RIB</label>
                          <div className='position-relative'>
                            <input
                              type='text'
                              placeholder='RIB'
                              autoComplete='off'
                              value={values.RIB}
                              onChange={(e) => {
                                setFieldValue("RIB", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.RIB && errors.RIB,
                                },
                                {
                                  'is-valid': touched.RIB && !errors.RIB,
                                }
                              )}
                            />
                            {touched.RIB && errors.RIB && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.RIB}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>
                      {/* end::Form group */}
                    </div>
                  </div>

                  <div className='d-flex flex-stack pt-6'>
                    <div className='mr-2'>
                      <button
                        onClick={() => {
                          setshow(false)
                        }}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        disabled={loading}
                        type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!loading && 'Submit'}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}

                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          }
        </div>
      </div>
    </>

  )
}

export { ThirdPartyEdit }
