import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { CONFIRME_MAIL_URL } from '../core/_requests';

export function ConfirmEmail() {
  const queryString = window.location.search;
  const [isActivate, setisActivate] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [Status, setStatus] = useState("")
  const [EmailState, setEmailState] = useState("")
  const [CodeState, setCodeState] = useState("")

  useEffect(
    () => {
      if (queryString) {
        if (queryString.includes("email")) {
          setEmailState(queryString.split("=")[1])
        } else if (queryString.includes("code")) {
          setCodeState(queryString.split("=")[1])
          VerifyCode(queryString.split("=")[1])
        }
      }
    },
    [queryString]
  )

  async function VerifyCode(_code: string) {
    setStatus("")
    setisLoading(true)
    try {
      await axios.post(CONFIRME_MAIL_URL, {
        "code": _code
      })
      setisActivate(true)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.errors) {
          var errors = ""
          var ViewModelErrors = error.response?.data.errors
          for (const [key, value] of Object.entries(ViewModelErrors)) {
            errors = errors + key + ": " + (value as Array<string>)[0] + "<br/>"
          }
          setStatus(errors)

        } else {
          setStatus(error.response?.data.message)
        }
      }
    }
    setisLoading(false)

  }
  return (CodeState ?
    <div className='text-center'>
      <div className="pt-lg-10 mb-10">
        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">Verify Your Email</h1>
        <div className="fs-3 fw-bold text-muted mb-10">{isLoading ? "Please wait verification in proccess ..." : isActivate ? "Welcome to warsha the best saas garage owner" : Status}</div>
        <div className="text-center mb-10">
          <Link to='/auth/login' className='btn btn-lg btn-primary fw-bolder'>
            Go to login
          </Link>
        </div>
      </div>
    </div>
    : <div className='text-center'>
      <div className="pt-lg-10 mb-10">
        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">Verify Your Email</h1>
        <div className="fs-3 fw-bold text-muted mb-10">We have sent an email to
          <span className="link-primary fw-bolder">{EmailState}</span>
          <br />pelase follow a link to verify your email.</div>
        <div className="text-center mb-10">
          <Link to='/auth/login' className='btn btn-lg btn-primary fw-bolder'>
            Skip for now
          </Link>
        </div>
      </div>
    </div>
  )
}
