import { KTIcon } from '../../../../../_metronic/helpers'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { DeletEmploye_URL, ThirdParty_URL } from '../../../auth/core/_requests'
import { useAuth } from '../../../auth'
import { ThirdPartyEdit } from './thirdPartyEdit'
import { Modal } from 'react-bootstrap'

const ThirdPartys = () => {
  const [FormikStatus, setFormikStatus] = useState("")
  const [models, setModels] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [show, setshow] = useState(false)
  const [modelId, setModelId] = useState(0)
  const { currentUser } = useAuth()
  const removeRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    GetData()
  }, [])

  useEffect(() => {
    setFormikStatus("")
  }, [loading])

  const GetData = async () => {
    setLoading(true)
    axios
      .get(ThirdParty_URL, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
      .then((x) => {
        setLoading(false)
        setModels(x['data']['thirdParty'])
        console.log(x['data']['thirdParty'])
      })
      .catch((error) => {
        setLoading(false)
        if (error.response?.data.errors) {
          var errors = ''
          var ViewModelErrors = error.response?.data.errors

          for (const [key, value] of Object.entries(ViewModelErrors)) {
            var val: any = value as any;
            errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
          }
          setFormikStatus(errors)
        } else {
          setFormikStatus(error.response?.data.message)
        }
      })
  }

  const DeletEmploye = () => {
    try {
      setLoading(true)
      axios
        .delete(DeletEmploye_URL + modelId, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
        .then((x) => {
          GetData()
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          if (error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
          }
        })
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className={`card`}>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={show}
        onHide={() => {
          setshow(false)
        }}
      >
        <ThirdPartyEdit id={modelId} getData={GetData} setshow={setshow} />
      </Modal>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Third Party</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Count : {(models ?? []).length}</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='New Third Party'
        >
          <a
            onClick={() => {
              setModelId(0);
              setshow(true)
            }}
            href='#'
            className='btn btn-sm btn-light-primary'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Third Party
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {loading && (
            <div className='w-100'>
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-lg align-middle ms-5'></span>
              </span>
            </div>
          )}
          {FormikStatus && (
            <div className=' mx-auto w-100 alert alert-danger'>
              <div className='alert-text font-weight-bold'
                dangerouslySetInnerHTML={{ __html: FormikStatus }}
              />
              {/* <div >{formik.status}</div> */}
            </div>
          )}
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-120px'>legal Name</th>
                <th className='min-w-120px'>Legal Form Id</th>
                <th className='min-w-120px'>cnss</th>
                <th className='min-w-150px'>rcCin</th>
                <th className='min-w-120px'>idF</th>
                <th className='min-w-120px'>Addresses</th>
                <th className='min-w-100px text-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            <tbody>
              {(models ?? []).map((x: any) => (
                <tr key={x.id}>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input widget-9-check'
                      type='checkbox'
                      value='1'
                    />
                  </div>
                </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-dark fw-bold fs-6'>
                          {/* <i className="fa-solid fa-phone text-primary mr-20" style={{ fontSize: '18px', paddingRight: '10px' }}></i> */}{x['legalName']}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-dark fw-bold fs-6'>
                          {/* <i className="fa-solid fa-phone text-primary mr-20" style={{ fontSize: '18px', paddingRight: '10px' }}></i> */}{x['legalFormId']}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold fs-6'>
                          {/* <i className="fa-solid fa-user-tie text-primary mr-20" style={{ fontSize: '18px', paddingRight: '10px' }}></i> */}{x['cnss']}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className='text-dark fw-bold fs-6'>{x['rcCin']}</span>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-dark fw-bold fs-6'>
                          {/* <i className="fa-solid fa-phone text-primary mr-20" style={{ fontSize: '18px', paddingRight: '10px' }}></i> */}{x['idF']}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-dark fw-bold fs-6'>
                          <i className="fa-solid fa-location-dot text-primary mr-20" style={{ fontSize: '18px', paddingRight: '10px' }}></i>
                          {x['addresses']['address1']}
                          {x['addresses']['address2'] != null && <>{" "}{x['addresses']['address2']}</>}{", "}
                          {x['addresses']['city']}{", "}
                          {x['addresses']['country']['name']}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <div className='card-toolbar'>
                        <button
                          onClick={() => {
                            setModelId(x.id);
                            setTimeout(() => {
                              setshow(true)
                            }, 10);
                          }}
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_employee_edit'
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                        </button>
                      </div>
                      <div className='card-toolbar'>
                        <button
                          onClick={() => {
                            setModelId(x.id);
                            setTimeout(() => {
                              removeRef.current?.click()
                            }, 10);
                          }}
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          <KTIcon iconName='trash' className='fs-3' />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          <div className='card-toolbar'>
            <button
              ref={removeRef}
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary d-none'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown1 deletEmploye={DeletEmploye} />
          </div>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div >
  )
}

function Dropdown1({ deletEmploye }: { deletEmploye: Function }) {
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px customShow' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Confirm</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Are you sure, You want to Delete?</label>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-danger me-2'
            data-kt-menu-dismiss='true'
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn btn-sm btn-danger'
            data-kt-menu-dismiss='true'
            onClick={() => {
              deletEmploye()
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default ThirdPartys