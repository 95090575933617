import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { WithChildren } from '../../../../_metronic/helpers'
var accessToken = window.localStorage.getItem("AuthObj");

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: accessToken !== undefined ? JSON.parse(String(accessToken)) : undefined,
  saveAuth: accessToken !== undefined ? JSON.parse(String(accessToken)) : undefined,
  currentUser: accessToken !== undefined ? JSON.parse(String(accessToken)) : undefined,
  setCurrentUser: accessToken !== undefined ? JSON.parse(String(accessToken)) : undefined,
  logout: () => { },
}
const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    window.localStorage.clear()

    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const data = accessToken !== undefined ? JSON.parse(String(accessToken)) : undefined
          if (data) {
            let AuthObj = {
              "email": data.email,
              "expiresOn": data.expiresOn,
              "firstName": data.firstName,
              "isAuthenticated": data.isAuthenticated,
              "lastName": data.lastName,
              "message": data.message,
              "profilePicture": data.profilePicture,
              "roles": data.roles,
              "username": data.username,
              "token": data.token,
              "role": data.role,
            }
            setCurrentUser({
              "id": 2,
              "profilePicture": data.profilePicture,
              "email": data.email,
              "first_name": data.firstName,
              "last_name": data.lastName,
              "fullname": data.firstName + " " + data.lastName,
              "companyName": "",
              "phone": "",
              "roles": data.roles,
              "language": 'en',
              "website": 'https://keenthemes.com',
              "auth": AuthObj,
              "token": data.token,
              "role": data.role,
            })
            // setCurrentUser(accessToken !== undefined ? JSON.parse(String(accessToken)) : undefined)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
