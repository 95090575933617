/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useRef, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { useAuth } from '../../../auth'
import axios from 'axios'
import { API_URL } from '../../../auth/core/_requests'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Event, SelectClass } from './types';
import { Modal } from 'react-bootstrap'
import { Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import Select from 'react-select';
import { RatingStar } from 'react-ts-rating-star'

const localizer = momentLocalizer(moment);
const eventPropGetter = (event: Event) => {
  const backgroundColor = event.color;
  return { style: { backgroundColor } };
};

const initialValues = {
  startDate: new Date(),
  phone: '',
  capacity: 0,
  statusId: 0,
}
interface initialValuesObj {
  startDate: Date,
  phone: string,
  capacity: number,
  statusId: number,
}

const Capacity = [
  { label: "00:00", value: 0 },
  { label: "00:30", value: 0.5 },
  { label: "01:00", value: 1 },
  { label: "01:30", value: 1.50 },
  { label: "02:00", value: 2 },
  { label: "02:30", value: 2.50 },
  { label: "03:00", value: 3 },
  { label: "03:30", value: 3.50 },
  { label: "04:00", value: 4 },
  { label: "04:30", value: 4.50 },
  { label: "05:00", value: 5 },
  { label: "05:30", value: 5.50 },
  { label: "06:00", value: 6 },
  { label: "06:30", value: 6.50 },
  { label: "07:00", value: 7 },
  { label: "07:30", value: 7.50 },
  { label: "08:00", value: 8 },
  { label: "08:30", value: 8.50 },
  { label: "09:00", value: 9 },
  { label: "09:30", value: 9.50 },
  { label: "10:00", value: 10 },
  { label: "10:30", value: 10.50 },
  { label: "11:00", value: 11 },
  { label: "11:30", value: 11.50 },
  { label: "12:00", value: 12 },
  { label: "12:30", value: 12.50 },
  { label: "13:00", value: 13 },
  { label: "13:30", value: 13.50 }
];

const EmployeeCalendar = ({ id, setshow }: { id?: number, setshow: Function }) => {
  const [Capacitie, setCapacitie] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [FormikStatus, setFormikStatus] = useState("")
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [events, setEvents] = useState<Event[]>([]);
  const [eventsOriginal, setEventsOriginal] = useState<any[]>([]);
  const [Status, setStatus] = useState<SelectClass[]>([]);
  const [showNew, setshowNew] = useState(false)
  const [CapacitieId, setCapacitieId] = useState(0)

  const { currentUser } = useAuth()

  const schema = Yup.object().shape({

    startDate: Yup.date()
      .required('Start Date is required'),
    capacity: Yup.number()
      .required('Capacity is required'),
    statusId: Yup.number()
      .required('Status is required'),
  
  })

  useEffect(
    () => {
      if (!!id && id != 0) GetData();
      else {
        setLoadingForm(true)
      }
      GetStatus();
    },
    []
  )

  function getDifferenceInHours(date1: Date, date2: Date): number {
    // Calculate the difference in milliseconds
    const differenceInMillis: number = date2.getTime() - date1.getTime();

    // Convert milliseconds to hours
    const differenceInHours: number = differenceInMillis / (1000 * 60 * 60);

    return differenceInHours;
  }
  /* useEffect(
    () => {
      if (!!CapacitieId && CapacitieId != 0) GetDataCapacitie();
    },
    [CapacitieId]
  ) */

  useEffect(
    () => {
      setLoading(false)
    },
    [showNew]
  )

  const GetData = async () => {
    setLoading(true)
    axios
      .get(API_URL + `/employees/${id}/capacities`, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
      .then((x) => {
        setLoadingForm(true)
        setLoading(false)

        var a = x['data']['data']['capacities'];
        setEventsOriginal(a);

        var b: any = [];

        a.forEach((x: any, i: number) => {
          b.push({
            id: x.id,
            title: x.note,
            start: new Date(x.startDate),
            end: new Date(x.endDate),
            color: x.status.name == 'Active' ? '#03CEA4' : '#D90429',
          })
        });
        setEvents(b);
        setEvents(b);
      })
      .catch((error) => {
        setLoadingForm(false)
        setLoading(false)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }
      })
  }

  /* const GetDataCapacitie = async () => {
    setLoading(true)
    axios
      .get(API_URL + `/employees/${id}/capacities/${CapacitieId}`, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
      .then((x) => {
        setLoadingForm(true)
        setLoading(false)

        console.log("iiooooo", x);

        var a = x['data']['data']['capacities'];
        setCapacitie(a);
      })
      .catch((error) => {
        setLoadingForm(false)
        setLoading(false)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }
      })
  } */

  const GetStatus = async () => {
    axios
      .get(API_URL + `/datas?keys=status`, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
      .then((x) => {
        var a = x['data']['data'][0]['status'];
        var b: SelectClass[] = [];

        a.forEach((x: any, i: number) => {
          b.push({
            label: x.name,
            value: x.id
          })
        });
        setStatus(b);
      })
      .catch((error) => {
        setLoadingForm(false)
        setLoading(false)
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        }
      })
  }

  const submitStep = async (values: initialValuesObj, actions: FormikValues) => {
    setLoading(true)
    try {
      if (!!CapacitieId && CapacitieId != 0)
        axios.put<any>(
          API_URL + `/capacities/${CapacitieId}`,
          {
            startDate: values.startDate,
            capacity: values.capacity,
            statusId: values.statusId,
          },
          { headers: { Authorization: `Bearer ${currentUser?.token}` } }
        ).then((x) => {
          actions.resetForm()
          setshowNew(false)
          setLoading(false)
          GetData()
        })
          .catch((error) => {
            setLoading(false)
            if (error.response?.data.errors) {
              var errors = ''
              var ViewModelErrors = error.response?.data.errors

              for (const [key, value] of Object.entries(ViewModelErrors)) {
                var val: any = value as any;
                errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
              }
              setFormikStatus(errors)
            } else {
              setFormikStatus(error.response?.data.message)
            }
          })
      else
        axios.post<any>(
          API_URL + `/employees/${id}/capacities`,
          {
            startDate: values.startDate,
            capacity: values.capacity,
            statusId: values.statusId,
          },
          { headers: { Authorization: `Bearer ${currentUser?.token}` } }
        ).then((x) => {
          actions.resetForm()
          setshowNew(false)
          setLoading(false)
          GetData()
        })
          .catch((error) => {
            setLoading(false)
            if (error.response?.data.errors) {
              var errors = ''
              var ViewModelErrors = error.response?.data.errors

              for (const [key, value] of Object.entries(ViewModelErrors)) {
                var val: any = value as any;
                errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
              }
              setFormikStatus(errors)
            } else {
              setFormikStatus(error.response?.data.message)
            }
          })
    } catch (error) { }
  }

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={showNew}
        onHide={() => {
          setshowNew(false)
        }}
      >
        <div className='modal-header pb-0 border-0 w-100'>
          <div className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-start'>
            <label className='form-label fw-bolder text-dark fs-6'>{CapacitieId != 0 ? `Edit Capacitie : ${CapacitieId}` : "New Capacitie"}</label>
          </div>
          <div
            onClick={() => {
              setshowNew(false)
            }}
            className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-end'
            data-bs-dismiss='modal'
          >
            <KTIcon iconName='cross' className='fs-1' />
          </div>
        </div>

        <div className='modal-body pt-0'>
          <div
            ref={stepperRef}
          >
            <Formik validationSchema={schema} enableReinitialize={false} initialValues={Capacitie ?? initialValues} onSubmit={submitStep}>
              {({
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form className='mx-auto w-100 pt-6 pb-10' id='kt_create_account_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <div className='row col-12'>
                      {/* begin::Form group Password */}
                      <div className='fv-row mb-6 col-9' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Start Date</label>
                          <div className='position-relative'>
                            <input
                              type='datetime-local'
                              placeholder='Start Date'
                              autoComplete='off'
                              value={values.startDate.toString().substring(0, 16)}
                              onChange={(e) => {
                                setFieldValue("startDate", e.target.value)
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.startDate && errors.startDate,
                                },
                                {
                                  'is-valid': touched.startDate && !errors.startDate,
                                }
                              )}
                            />
                            {touched.startDate && errors.startDate && typeof errors.startDate === 'string' && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">{errors.startDate}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>

                      <div className='mb-10 col-3'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Capacity</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={{
                              label: Capacity.find(x => x.value == values.capacity)?.label,
                              value: values.capacity
                            }}
                            onChange={(e) => {
                              setFieldValue("capacity", e?.value)
                            }}
                            isSearchable={true}
                            name="capacity"
                            options={Capacity}
                          />
                          {touched.capacity && errors.capacity && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.capacity}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='mb-10 col-12'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Status</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={{
                              label: Status.find(x => x.value == values.statusId)?.label,
                              value: values.statusId
                            }}
                            onChange={(e) => {
                              setFieldValue("statusId", e?.value)
                            }}
                            isSearchable={true}
                            name="statusId"
                            options={Status}
                          />
                          {touched.statusId && errors.statusId && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.statusId}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                  
                      {/* end::Form group */}
                    </div>
                  </div>

                  <div className='d-flex flex-stack pt-6'>
                    <div className='mr-2'>
                      <button
                        onClick={() => {
                          setshowNew(false)
                        }}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        disabled={loading}
                        type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!loading && 'Submit'}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>

      <div className='modal-header pb-0 border-0 w-100'>
        <div className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-start'>
          <label className='form-label fw-bolder text-dark fs-6'>{!id ? 'Add new Employee' : 'Edit Employee : ' + (id ?? '')}</label>
          <button
            onClick={() => {
              setshowNew(true)
              setCapacitieId(0)
            }}
            type='button'
            className='btn btn-bg-success btn-active-color-primary btn-sm mx-3 text-white'
          >
            <KTIcon iconName='plus' className='fs-3 text-white' /> New Capacitie
          </button>

        </div>
        <div
          onClick={() => {
            setshow(false)
          }}
          className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-end'
          data-bs-dismiss='modal'
        >
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>

      <div className='modal-body'>
        <div
          ref={stepperRef}
        >
          <DndProvider backend={HTML5Backend}>
            <div className="App">
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                eventPropGetter={eventPropGetter}
                onSelectEvent={
                  (event) => {
                    setCapacitieId(event.id)
                    var first = eventsOriginal.filter(x => x.id == event.id);
                    const date1 = new Date(first[0].startDate);
                    const date2 = new Date(first[0].endDate);

                    setshowNew(true)
                    setCapacitie({
                      startDate: first[0].startDate,
                      statusId: first[0].status?.id,
                      capacity: getDifferenceInHours(date1, date2)
                    })
                  }
                }
              />
            </div>
          </DndProvider>
        </div>
      </div>
    </>

  )
}

export { EmployeeCalendar }
