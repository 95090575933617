/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { LOGIN_URL, Verify_URL } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import axios from 'axios'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  /* code: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Code is required'), */
})

const initialValues = {
  email: 'baabit1@gmail.com',
  code: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [verify, setverify] = useState(false)
  const [FormikStatus, setFormikStatus] = useState("")
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        if (verify) {
          const { data: auth } = await axios.post<any>(Verify_URL, {
            email: values.email,
            code: values.code,
          })
          let AuthObj = {
            email: auth.data.email,
            expiresOn: new Date(),
            firstName: auth.data.firstName,
            isAuthenticated: auth.data.isAuthenticated,
            lastName: auth.data.lastName,
            message: auth.data.message,
            profilePicture: auth.data.profilePicture,
            roles: auth.data.roles,
            username: auth.data.username,
            token: auth.data.token,
            role: auth.data.role,
          }
          window.localStorage.setItem('AuthObj', JSON.stringify(AuthObj))
          console.log('data', auth)
          saveAuth(AuthObj)
          setCurrentUser({
            id: 2,
            profilePicture: auth.data.profilePicture,
            email: auth.data.username,
            first_name: auth.data.firstName,
            last_name: auth.data.lastName,
            fullname: auth.data.firstName + ' ' + auth.lastName,
            companyName: auth.data.username,
            phone: 'string',
            roles: auth.data.roles,
            token: auth.data.token,
            role: auth.data.role,
            language: 'en',
            website: 'https://keenthemes.com',
            auth: auth,
          })
          if (auth.data.role === "MANAGER") navigate(`/employees`);
          else navigate(`/dashboard`);
        } else {
          const { data: auth } = await axios.post<any>(LOGIN_URL, {
            email: values.email,
          })
          setverify(true)
          setFormikStatus("")
          setLoading(false)
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.data.errors) {

            var errors = ''
            var ViewModelErrors = error.response?.data.errors

            for (const [key, value] of Object.entries(ViewModelErrors)) {
              var val: any = value as any;
              errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
            }
            setFormikStatus(errors)
            setStatus(errors)
          } else {
            setFormikStatus(error.response?.data.message)
            setStatus(error.response?.data.message)
          }
        } else {
          console.error(error)
        }
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      <div className='row g-3 mb-9'>
        {/* begin::Col */}
        <div className='col-md-4'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/facebook-5.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/facebook-5.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Facebook
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col-md-4'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              style={{ height: '18px' }}
              src={toAbsoluteUrl('/media/svg/brand-logos/tiktok1.svg')}
              className='theme-light-show  me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/tiktok1.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with TikTok
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
        {/* begin::Col */}
        <div className='col-md-4'>
          {/* begin::Google link */}
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}

      {/* begin::Separator */}
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>
      {/* end::Separator */}

      {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'
            dangerouslySetInnerHTML={{ __html: formik.status }}
          />
          <div >{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

      {FormikStatus && (
        <div className=' mx-auto w-100 alert alert-danger'>
          <div className='alert-text font-weight-bold'
            dangerouslySetInnerHTML={{ __html: FormikStatus }}
          />
          {/* <div >{formik.status}</div> */}
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          disabled={verify}
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      {verify && (
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Code</label>
          <input
            placeholder='Code'
            {...formik.getFieldProps('code')}
            className={clsx(
              'form-control bg-transparent'
              /*  { 'is-invalid': formik.touched.email && formik.errors.email },
             {
               'is-valid': formik.touched.email && !formik.errors.email,
             } */
            )}
            type='text'
            name='code'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div>
      )}
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div> */}
      {/* end::Form group */}

      {/* begin::Wrapper */}
      {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div /> */}

      {/* begin::Link */}
      {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
      {/* end::Link */}
      {/* </div> */}
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{verify ? 'Verify' : 'Continue'}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  )
}
