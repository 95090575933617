//import { KTIcon } from '../../../../../_metronic/helpers'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
//import { DeletEmploye_URL } from '../../../auth/core/_requests'
//import { useAuth } from '../../../auth'
//import { EmployeeEdit } from './employeeEdit'
import { Form, Modal } from 'react-bootstrap'
import { useAuth } from '../../../../auth'
import { API_URL } from '../../../../auth/core/_requests'
import { KTIcon } from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { Formik, FormikValues } from 'formik'
import clsx from 'clsx'
import Select from 'react-select';
//import { EmployeeCalendar } from './employeeCalendar'

const initialValues = {
  startDate: new Date(),
  phone: '',
  capacity: 0,
  statusId: 0,
}
interface initialValuesObj {
  startDate: Date,
  phone: string,
  capacity: number,
  statusId: number,
}


const EmployeeSpecialties = ({ id, setshow }: { id?: number, setshow: Function }) => {
  const [FormikStatus, setFormikStatus] = useState("")
  const [employees, setEmployees] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [showNew, setshowNew] = useState(false)
  const [showCalendar, setshowCalendar] = useState(false)
  const [itemId, setItemId] = useState(0)
  const [item, setItem] = useState<any>(null)
  const { currentUser } = useAuth()
  const removeRef = useRef<HTMLButtonElement | null>(null)
  const stepperRef = useRef<HTMLDivElement | null>(null)

  const schema = Yup.object().shape({

    startDate: Yup.date()
      .required('Start Date is required'),
    capacity: Yup.number()
      .required('Capacity is required'),
    statusId: Yup.number()
      .required('Status is required'),

  })

  useEffect(() => {
    GetData()
  }, [])

  useEffect(() => {
    setFormikStatus("")
  }, [loading])

  const GetData = async () => {
    setLoading(true)
    axios
      .get(API_URL + `/employees/${60}/specialties`, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
      .then((x) => {
        setLoading(false)
        setEmployees(x['data']['data']['specialties'])
      })
      .catch((error) => {
        setLoading(false)
        if (error.response?.data.errors) {
          var errors = ''
          var ViewModelErrors = error.response?.data.errors

          for (const [key, value] of Object.entries(ViewModelErrors)) {
            var val: any = value as any;
            errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
          }
          setFormikStatus(errors)
        } else {
          setFormikStatus(error.response?.data.message)
        }
      })
  }

  const DeletEmploye = () => {
    /*  try {
       setLoading(true)
       axios
         .delete(DeletEmploye_URL + employeId, { headers: { Authorization: `Bearer ${currentUser?.token}` } })
         .then((x) => {
           GetData()
           setLoading(false)
         })
         .catch((error) => {
           setLoading(false)
           if (error.response) {
             console.log(error.response.data)
             console.log(error.response.status)
             console.log(error.response.headers)
           }
         })
     } catch (error) {
       setLoading(false)
     } */
  }

  const submitStep = async (values: initialValuesObj, actions: FormikValues) => {
    setLoading(true)
    try {
      /* if (!!CapacitieId && CapacitieId != 0)
        axios.put<any>(
          API_URL + `/capacities/${CapacitieId}`,
          {
            startDate: values.startDate,
            capacity: values.capacity,
            statusId: values.statusId,
          },
          { headers: { Authorization: `Bearer ${currentUser?.token}` } }
        ).then((x) => {
          actions.resetForm()
          setshowNew(false)
          setLoading(false)
          GetData()
        })
          .catch((error) => {
            setLoading(false)
            if (error.response?.data.errors) {
              var errors = ''
              var ViewModelErrors = error.response?.data.errors

              for (const [key, value] of Object.entries(ViewModelErrors)) {
                var val: any = value as any;
                errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
              }
              setFormikStatus(errors)
            } else {
              setFormikStatus(error.response?.data.message)
            }
          })
      else
        axios.post<any>(
          API_URL + `/employees/${id}/capacities`,
          {
            startDate: values.startDate,
            capacity: values.capacity,
            statusId: values.statusId,
          },
          { headers: { Authorization: `Bearer ${currentUser?.token}` } }
        ).then((x) => {
          actions.resetForm()
          setshowNew(false)
          setLoading(false)
          GetData()
        })
          .catch((error) => {
            setLoading(false)
            if (error.response?.data.errors) {
              var errors = ''
              var ViewModelErrors = error.response?.data.errors

              for (const [key, value] of Object.entries(ViewModelErrors)) {
                var val: any = value as any;
                errors = errors + val.field + ': ' + (val.message ?? "") + '<br/>'
              }
              setFormikStatus(errors)
            } else {
              setFormikStatus(error.response?.data.message)
            }
          }) */
    } catch (error) { }
  }

  return (
    <div className={`card`}>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={showNew}
        onHide={() => {
          setshowNew(false)
        }}
      >
        <div className='modal-header pb-0 border-0 w-100'>
          <div className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-start'>
            <label className='form-label fw-bolder text-dark fs-6'>{itemId != 0 ? `Edit Speciality : ${itemId}` : "New Speciality"}</label>
          </div>
          <div
            onClick={() => {
              setshowNew(false)
            }}
            className='btn btn-sm btn-icon btn-active-color-primary w-50 justify-content-end'
            data-bs-dismiss='modal'
          >
            <KTIcon iconName='cross' className='fs-1' />
          </div>
        </div>

        <div className='modal-body pt-0'>
          <div
            ref={stepperRef}
          >
            <Formik validationSchema={schema} enableReinitialize={false} initialValues={item ?? initialValues} onSubmit={submitStep}>
              {({
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <Form className='mx-auto w-100 pt-6 pb-10' id='kt_create_account_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <div className='row col-12'>
                      {/* begin::Form group Password */}


                      <div className='mb-10 col-12'>
                        <div className='col-lg-12 fv-row'>
                          <label className='form-label fw-bolder text-dark fs-6 required'>Activity</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            /* value={{
                              label: Capacity.find(x => x.value == values.capacity)?.label,
                              value: values.capacity
                            }} */
                            onChange={(e) => {
                              /* setFieldValue("capacity", e?.value) */
                            }}
                            isSearchable={true}
                            name="capacity"
                          /* options={Capacity} */
                          />
                          {touched.capacity && errors.capacity && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.capacity}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='fv-row mb-6 col-4' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6 '>Focused</label>
                          <div className='position-relative'>
                            <input
                              type='number'
                              placeholder='Focused'
                              autoComplete='off'
                              /* value={values.startDate.toString().substring(0, 16)} */
                              onChange={(e) => {
                                /* setFieldValue("startDate", e.target.value) */
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.startDate && errors.startDate,
                                },
                                {
                                  'is-valid': touched.startDate && !errors.startDate,
                                }
                              )}
                            />
                            {touched.startDate && errors.startDate && typeof errors.startDate === 'string' && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">{errors.startDate}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>

                      <div className='fv-row mb-6 col-4' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6 '>Preference</label>
                          <div className='position-relative'>
                            <input
                              type='number'
                              placeholder='Preference'
                              autoComplete='off'
                              /* value={values.startDate.toString().substring(0, 16)} */
                              onChange={(e) => {
                                /* setFieldValue("startDate", e.target.value) */
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.startDate && errors.startDate,
                                },
                                {
                                  'is-valid': touched.startDate && !errors.startDate,
                                }
                              )}
                            />
                            {touched.startDate && errors.startDate && typeof errors.startDate === 'string' && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">{errors.startDate}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>

                      <div className='fv-row mb-6 col-4' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6 '>Efficiency</label>
                          <div className='position-relative'>
                            <input
                              type='number'
                              placeholder='Preference'
                              autoComplete='off'
                              /* value={values.startDate.toString().substring(0, 16)} */
                              onChange={(e) => {
                                /* setFieldValue("startDate", e.target.value) */
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.startDate && errors.startDate,
                                },
                                {
                                  'is-valid': touched.startDate && !errors.startDate,
                                }
                              )}
                            />
                            {touched.startDate && errors.startDate && typeof errors.startDate === 'string' && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">{errors.startDate}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>

                      <div className='fv-row mb-6 col-12' data-kt-password-meter='true'>
                        <div className='mb-1'>
                          <label className='form-label fw-bolder text-dark fs-6 '>Note</label>
                          <div className='position-relative'>
                            <textarea
                              rows={3}
                              /* value={values.startDate.toString().substring(0, 16)} */
                              onChange={(e) => {
                                /* setFieldValue("startDate", e.target.value) */
                              }}
                              className={clsx(
                                'form-control bg-transparent',
                                {
                                  'is-invalid': touched.startDate && errors.startDate,
                                },
                                {
                                  'is-valid': touched.startDate && !errors.startDate,
                                }
                              )}
                            />
                            {touched.startDate && errors.startDate && typeof errors.startDate === 'string' && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  <span role="alert">{errors.startDate}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* begin::Meter */}
                          {/* end::Meter */}
                        </div>
                      </div>


                      {/* end::Form group */}
                    </div>
                  </div>

                  <div className='d-flex flex-stack pt-6'>
                    <div className='mr-2'>
                      <button
                        onClick={() => {
                          setshowNew(false)
                        }}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-bs-dismiss='modal'
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      <button
                        disabled={loading}
                        type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!loading && 'Submit'}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>


      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Specialties</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Count : {(employees ?? []).length}</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='New Employee'
        >
          <a
            onClick={() => {
              setItemId(0);
              setshowNew(true) 
            }}
            href='#'
            className='btn btn-sm btn-light-primary'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Specialization
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {loading && (
            <div className='w-100'>
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-lg align-middle ms-5'></span>
              </span>
            </div>
          )}
          {FormikStatus && (
            <div className=' mx-auto w-100 alert alert-danger'>
              <div className='alert-text font-weight-bold'
                dangerouslySetInnerHTML={{ __html: FormikStatus }}
              />
              {/* <div >{formik.status}</div> */}
            </div>
          )}
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-120px'>Activity</th>
                {/* <th className='min-w-120px'>Email</th> */}
                <th className='min-w-120px'>Note</th>
                <th className='min-w-150px'>Efficiency</th>
                <th className='min-w-120px'>Experience</th>
                <th className='min-w-120px'>Focused</th>
                <th className='min-w-120px'>Preference</th>
                <th className='min-w-100px text-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            <tbody>
              {(employees ?? []).map((x: any) => (
                <tr key={x.id}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value='1'
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold fs-6'>
                          <i className="fa-solid fa-user-tie text-primary mr-20" style={{ fontSize: '18px', paddingRight: '10px' }}></i>{x['activity']['name']} {x['employee']['email']}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className='text-dark fw-bold fs-6'>{x['note']}</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold fs-6'>{x['efficiency']}</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold fs-6'>{x['experience']}</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold fs-6'>{x['focused']} %</span>
                  </td>
                  <td>
                    <span className='text-dark fw-bold fs-6'>{x['preference']}</span>
                  </td>

                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <div className='card-toolbar'>
                        <button
                          onClick={() => {
                            setItemId(x.id);
                            setTimeout(() => {
                              setshowNew(true)
                            }, 10);
                          }}
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_employee_edit'
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                        </button>
                      </div>
                      <div className='card-toolbar'>
                        <button
                          onClick={() => {
                            setItemId(x.id);
                            /* setTimeout(() => {
                              removeRef.current?.click()
                            }, 10); */
                          }}
                          type='button'
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          <KTIcon iconName='trash' className='fs-3' />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          <div className='card-toolbar'>
            <button
              ref={removeRef}
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary d-none'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown1 deletEmploye={DeletEmploye} />
          </div>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div >
  )
}

function Dropdown1({ deletEmploye }: { deletEmploye: Function }) {
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px customShow' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Confirm</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Are you sure, You want to Delete?</label>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-light btn-active-light-danger me-2'
            data-kt-menu-dismiss='true'
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn btn-sm btn-danger'
            data-kt-menu-dismiss='true'
            onClick={() => {
              deletEmploye()
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default EmployeeSpecialties