import axios from 'axios'
import { AuthModel } from './_models'

export const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/accounts/login`
export const REGISTER_URL = `${API_URL}/accounts/register`
export const DeletEmploye_URL = `${API_URL}/employees/`
export const Profile_URL = `${API_URL}/accounts/profile`
export const Profile_Photo_URL = `${API_URL}/accounts/profile/photo`
export const Profile_Logo_URL = `${API_URL}/accounts/profile/logo`
export const Employe_URL = `${API_URL}/employees`
export const Verify_URL = `${API_URL}/accounts/verify`
export const CONFIRME_MAIL_URL = `${API_URL}/Account/ConfirmEmail`
export const REQUEST_PASSWORD_URL = `${API_URL}/Account/ForgetPassword`
export const RESET_PASSWORD_URL = `${API_URL}/Account/ResetPassword`
export const CHANGE_PASSWORD_URL = `${API_URL}/Account/ChangePassword`
export const GET_PROFILE_URL = `${API_URL}/Account/Profile`
export const PUT_PROFILE_URL = `${API_URL}/Account/Profile`

export const ThirdParty_URL = `${API_URL}/third-party`
export const ThirdPartyOption_URL = `${API_URL}/datas?keys=countries,banks,legalforms,currencies,paymentmethods,paymentterms`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username: email,
    password: password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
  phoneNumber: string,
  site: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  zipCode: string,
  country: string,
  companyName: string,
) {
  return axios.post(REGISTER_URL, {
    email,
    firstName: firstname,
    lastName: lastname,
    password,
    confirmPassword: password_confirmation,
    phoneNumber: phoneNumber,
    site: site,
    address1: address1,
    address2: address2,
    city: city,
    state: state,
    zipCode: zipCode,
    country: country,
    companyName: companyName,
    "role": "BUSINESSUSER"
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}
