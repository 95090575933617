import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import qs from 'qs'
import { ID, QueryResponseContextProps, QueryState } from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, { filter: ['page', 'items_per_page'], skipNulls: true })
  const sort = qs.stringify(state, { filter: ['sort', 'order'], skipNulls: true })
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `filter_${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}
const Contries = [{ label: "USA", value: "USA" }, { label: "Canada", value: "Canada" }, { label: "Morocco", value: "Morocco" }, { label: "Arabie Saoudit", value: "Arabie Saoudit" }, { label: "Egypt", value: "Egypt" }, { label: "France", value: "France" }];

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}


const TimeZoneList = [
  {
    "value": "International Date Line West",
    "label": "(GMT-11:00) International Date Line West"
  },
  {
    "value": "Midway Island",
    "label": "(GMT-11:00) Midway Island"
  },
  {
    "value": "Samoa",
    "label": "(GMT-11:00) Samoa"
  },
  {
    "value": "Hawaii",
    "label": "(GMT-10:00) Hawaii"
  },
  {
    "value": "Alaska",
    "label": "(GMT-08:00) Alaska"
  },
  {
    "value": "Pacific Time (US &amp; Canada)",
    "label": "(GMT-07:00) Pacific Time (US &amp; Canada)"
  },
  {
    "value": "Tijuana",
    "label": "(GMT-07:00) Tijuana"
  },
  {
    "value": "Arizona",
    "label": "(GMT-07:00) Arizona"
  },
  {
    "value": "Mountain Time (US &amp; Canada)",
    "label": "(GMT-06:00) Mountain Time (US &amp; Canada)"
  },
  {
    "value": "Chihuahua",
    "label": "(GMT-06:00) Chihuahua"
  },
  {
    "value": "Mazatlan",
    "label": "(GMT-06:00) Mazatlan"
  },
  {
    "value": "Saskatchewan",
    "label": "(GMT-06:00) Saskatchewan"
  },
  {
    "value": "Central America",
    "label": "(GMT-06:00) Central America"
  },
  {
    "value": "Central Time (US &amp; Canada)",
    "label": "(GMT-05:00) Central Time (US &amp; Canada)"
  },
  {
    "value": "Guadalajara",
    "label": "(GMT-05:00) Guadalajara"
  },
  {
    "value": "Mexico City",
    "label": "(GMT-05:00) Mexico City"
  },
  {
    "value": "Monterrey",
    "label": "(GMT-05:00) Monterrey"
  },
  {
    "value": "Bogota",
    "label": "(GMT-05:00) Bogota"
  },
  {
    "value": "Lima",
    "label": "(GMT-05:00) Lima"
  },
  {
    "value": "Quito",
    "label": "(GMT-05:00) Quito"
  },
  {
    "value": "Eastern Time (US &amp; Canada)",
    "label": "(GMT-04:00) Eastern Time (US &amp; Canada)"
  },
  {
    "value": "Indiana (East)",
    "label": "(GMT-04:00) Indiana (East)"
  },
  {
    "value": "Caracas",
    "label": "(GMT-04:00) Caracas"
  },
  {
    "value": "La Paz",
    "label": "(GMT-04:00) La Paz"
  },
  {
    "value": "Georgetown",
    "label": "(GMT-04:00) Georgetown"
  },
  {
    "value": "Atlantic Time (Canada)",
    "label": "(GMT-03:00) Atlantic Time (Canada)"
  },
  {
    "value": "Santiago",
    "label": "(GMT-03:00) Santiago"
  },
  {
    "value": "Brasilia",
    "label": "(GMT-03:00) Brasilia"
  },
  {
    "value": "Buenos Aires",
    "label": "(GMT-03:00) Buenos Aires"
  },
  {
    "value": "Newfoundland",
    "label": "(GMT-02:30) Newfoundland"
  },
  {
    "value": "Greenland",
    "label": "(GMT-02:00) Greenland"
  },
  {
    "value": "Mid-Atlantic",
    "label": "(GMT-02:00) Mid-Atlantic"
  },
  {
    "value": "Cape Verde Is.",
    "label": "(GMT-01:00) Cape Verde Is."
  },
  {
    "value": "Azores",
    "label": "(GMT) Azores"
  },
  {
    "value": "Monrovia",
    "label": "(GMT) Monrovia"
  },
  {
    "value": "UTC",
    "label": "(GMT) UTC"
  },
  {
    "value": "Dublin",
    "label": "(GMT+01:00) Dublin"
  },
  {
    "value": "Edinburgh",
    "label": "(GMT+01:00) Edinburgh"
  },
  {
    "value": "Lisbon",
    "label": "(GMT+01:00) Lisbon"
  },
  {
    "value": "London",
    "label": "(GMT+01:00) London"
  },
  {
    "value": "Casablanca",
    "label": "(GMT+01:00) Casablanca"
  },
  {
    "value": "West Central Africa",
    "label": "(GMT+01:00) West Central Africa"
  },
  {
    "value": "Belgrade",
    "label": "(GMT+02:00) Belgrade"
  },
  {
    "value": "Bratislava",
    "label": "(GMT+02:00) Bratislava"
  },
  {
    "value": "Budapest",
    "label": "(GMT+02:00) Budapest"
  },
  {
    "value": "Ljubljana",
    "label": "(GMT+02:00) Ljubljana"
  },
  {
    "value": "Prague",
    "label": "(GMT+02:00) Prague"
  },
  {
    "value": "Sarajevo",
    "label": "(GMT+02:00) Sarajevo"
  },
  {
    "value": "Skopje",
    "label": "(GMT+02:00) Skopje"
  },
  {
    "value": "Warsaw",
    "label": "(GMT+02:00) Warsaw"
  },
  {
    "value": "Zagreb",
    "label": "(GMT+02:00) Zagreb"
  },
  {
    "value": "Brussels",
    "label": "(GMT+02:00) Brussels"
  },
  {
    "value": "Copenhagen",
    "label": "(GMT+02:00) Copenhagen"
  },
  {
    "value": "Madrid",
    "label": "(GMT+02:00) Madrid"
  },
  {
    "value": "Paris",
    "label": "(GMT+02:00) Paris"
  },
  {
    "value": "Amsterdam",
    "label": "(GMT+02:00) Amsterdam"
  },
  {
    "value": "Berlin",
    "label": "(GMT+02:00) Berlin"
  },
  {
    "value": "Bern",
    "label": "(GMT+02:00) Bern"
  },
  {
    "value": "Rome",
    "label": "(GMT+02:00) Rome"
  },
  {
    "value": "Stockholm",
    "label": "(GMT+02:00) Stockholm"
  },
  {
    "value": "Vienna",
    "label": "(GMT+02:00) Vienna"
  },
  {
    "value": "Cairo",
    "label": "(GMT+02:00) Cairo"
  },
  {
    "value": "Harare",
    "label": "(GMT+02:00) Harare"
  },
  {
    "value": "Pretoria",
    "label": "(GMT+02:00) Pretoria"
  },
  {
    "value": "Bucharest",
    "label": "(GMT+03:00) Bucharest"
  },
  {
    "value": "Helsinki",
    "label": "(GMT+03:00) Helsinki"
  },
  {
    "value": "Kiev",
    "label": "(GMT+03:00) Kiev"
  },
  {
    "value": "Kyiv",
    "label": "(GMT+03:00) Kyiv"
  },
  {
    "value": "Riga",
    "label": "(GMT+03:00) Riga"
  },
  {
    "value": "Sofia",
    "label": "(GMT+03:00) Sofia"
  },
  {
    "value": "Tallinn",
    "label": "(GMT+03:00) Tallinn"
  },
  {
    "value": "Vilnius",
    "label": "(GMT+03:00) Vilnius"
  },
  {
    "value": "Athens",
    "label": "(GMT+03:00) Athens"
  },
  {
    "value": "Istanbul",
    "label": "(GMT+03:00) Istanbul"
  },
  {
    "value": "Minsk",
    "label": "(GMT+03:00) Minsk"
  },
  {
    "value": "Jerusalem",
    "label": "(GMT+03:00) Jerusalem"
  },
  {
    "value": "Moscow",
    "label": "(GMT+03:00) Moscow"
  },
  {
    "value": "St. Petersburg",
    "label": "(GMT+03:00) St. Petersburg"
  },
  {
    "value": "Volgograd",
    "label": "(GMT+03:00) Volgograd"
  },
  {
    "value": "Kuwait",
    "label": "(GMT+03:00) Kuwait"
  },
  {
    "value": "Riyadh",
    "label": "(GMT+03:00) Riyadh"
  },
  {
    "value": "Nairobi",
    "label": "(GMT+03:00) Nairobi"
  },
  {
    "value": "Baghdad",
    "label": "(GMT+03:00) Baghdad"
  },
  {
    "value": "Abu Dhabi",
    "label": "(GMT+04:00) Abu Dhabi"
  },
  {
    "value": "Muscat",
    "label": "(GMT+04:00) Muscat"
  },
  {
    "value": "Baku",
    "label": "(GMT+04:00) Baku"
  },
  {
    "value": "Tbilisi",
    "label": "(GMT+04:00) Tbilisi"
  },
  {
    "value": "Yerevan",
    "label": "(GMT+04:00) Yerevan"
  },
  {
    "value": "Tehran",
    "label": "(GMT+04:30) Tehran"
  },
  {
    "value": "Kabul",
    "label": "(GMT+04:30) Kabul"
  },
  {
    "value": "Ekaterinburg",
    "label": "(GMT+05:00) Ekaterinburg"
  },
  {
    "value": "Islamabad",
    "label": "(GMT+05:00) Islamabad"
  },
  {
    "value": "Karachi",
    "label": "(GMT+05:00) Karachi"
  },
  {
    "value": "Tashkent",
    "label": "(GMT+05:00) Tashkent"
  },
  {
    "value": "Chennai",
    "label": "(GMT+05:30) Chennai"
  },
  {
    "value": "Kolkata",
    "label": "(GMT+05:30) Kolkata"
  },
  {
    "value": "Mumbai",
    "label": "(GMT+05:30) Mumbai"
  },
  {
    "value": "New Delhi",
    "label": "(GMT+05:30) New Delhi"
  },
  {
    "value": "Sri Jayawardenepura",
    "label": "(GMT+05:30) Sri Jayawardenepura"
  },
  {
    "value": "Kathmandu",
    "label": "(GMT+05:45) Kathmandu"
  },
  {
    "value": "Astana",
    "label": "(GMT+06:00) Astana"
  },
  {
    "value": "Dhaka",
    "label": "(GMT+06:00) Dhaka"
  },
  {
    "value": "Almaty",
    "label": "(GMT+06:00) Almaty"
  },
  {
    "value": "Urumqi",
    "label": "(GMT+06:00) Urumqi"
  },
  {
    "value": "Rangoon",
    "label": "(GMT+06:30) Rangoon"
  },
  {
    "value": "Novosibirsk",
    "label": "(GMT+07:00) Novosibirsk"
  },
  {
    "value": "Bangkok",
    "label": "(GMT+07:00) Bangkok"
  },
  {
    "value": "Hanoi",
    "label": "(GMT+07:00) Hanoi"
  },
  {
    "value": "Jakarta",
    "label": "(GMT+07:00) Jakarta"
  },
  {
    "value": "Krasnoyarsk",
    "label": "(GMT+07:00) Krasnoyarsk"
  },
  {
    "value": "Beijing",
    "label": "(GMT+08:00) Beijing"
  },
  {
    "value": "Chongqing",
    "label": "(GMT+08:00) Chongqing"
  },
  {
    "value": "Hong Kong",
    "label": "(GMT+08:00) Hong Kong"
  },
  {
    "value": "Kuala Lumpur",
    "label": "(GMT+08:00) Kuala Lumpur"
  },
  {
    "value": "Singapore",
    "label": "(GMT+08:00) Singapore"
  },
  {
    "value": "Taipei",
    "label": "(GMT+08:00) Taipei"
  },
  {
    "value": "Perth",
    "label": "(GMT+08:00) Perth"
  },
  {
    "value": "Irkutsk",
    "label": "(GMT+08:00) Irkutsk"
  },
  {
    "value": "Ulaan Bataar",
    "label": "(GMT+08:00) Ulaan Bataar"
  },
  {
    "value": "Seoul",
    "label": "(GMT+09:00) Seoul"
  },
  {
    "value": "Osaka",
    "label": "(GMT+09:00) Osaka"
  },
  {
    "value": "Sapporo",
    "label": "(GMT+09:00) Sapporo"
  },
  {
    "value": "Tokyo",
    "label": "(GMT+09:00) Tokyo"
  },
  {
    "value": "Yakutsk",
    "label": "(GMT+09:00) Yakutsk"
  },
  {
    "value": "Darwin",
    "label": "(GMT+09:30) Darwin"
  },
  {
    "value": "Adelaide",
    "label": "(GMT+09:30) Adelaide"
  },
  {
    "value": "Canberra",
    "label": "(GMT+10:00) Canberra"
  },
  {
    "value": "Melbourne",
    "label": "(GMT+10:00) Melbourne"
  },
  {
    "value": "Sydney",
    "label": "(GMT+10:00) Sydney"
  },
  {
    "value": "Brisbane",
    "label": "(GMT+10:00) Brisbane"
  },
  {
    "value": "Hobart",
    "label": "(GMT+10:00) Hobart"
  },
  {
    "value": "Vladivostok",
    "label": "(GMT+10:00) Vladivostok"
  },
  {
    "value": "Guam",
    "label": "(GMT+10:00) Guam"
  },
  {
    "value": "Port Moresby",
    "label": "(GMT+10:00) Port Moresby"
  },
  {
    "value": "Solomon Is.",
    "label": "(GMT+10:00) Solomon Is."
  },
  {
    "value": "Magadan",
    "label": "(GMT+11:00) Magadan"
  },
  {
    "value": "New Caledonia",
    "label": "(GMT+11:00) New Caledonia"
  },
  {
    "value": "Fiji",
    "label": "(GMT+12:00) Fiji"
  },
  {
    "value": "Kamchatka",
    "label": "(GMT+12:00) Kamchatka"
  },
  {
    "value": "Marshall Is.",
    "label": "(GMT+12:00) Marshall Is."
  },
  {
    "value": "Auckland",
    "label": "(GMT+12:00) Auckland"
  },
  {
    "value": "Wellington",
    "label": "(GMT+12:00) Wellington"
  },
  {
    "value": "Nuku",
    "label": "alofa(GMT+13:00) Nuku"
  }
]

const CurrencyList = [
  {
    label: "USD", value: "USD - USA dollar"
  },
  {
    label: "GBP", value: "GBP - British pound"
  },
  {
    label: "AUD", value: "AUD - Australian dollar"
  },
  {
    label: "JPY", value: "JPY - Japanese yen"
  },
  {
    label: "SEK", value: "SEK - Swedish krona"
  },
  {
    label: "CAD", value: "CAD - Canadian dollar"
  },
  {
    label: "CHF", value: "CHF - Swiss franc"
  }
]
export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  Contries,
  TimeZoneList,
  CurrencyList
}
